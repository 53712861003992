import React from 'react'
import { useEffect } from 'react'
import { addBanner } from '../../actions/utilitiesAction'
import Add from '../../component/Banner/AddBanner'
import constant from '../../constant'
const AddBanner = () => {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const onSubmit = (model) => {
        addBanner(model).then((res) => {
            debugger
            if (res.status) {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
                window.location.reload();
            }
            else {
                alert(res.error)

            }
        }).catch((err) => {
            alert(err)
        })
    }
    useEffect(() => {
        document.title = constant.title.AddBanner
    }, [])


    return (
        <React.Fragment>
            <Add response={response} showMsg={showMsg} onSubmit={onSubmit} />
        </React.Fragment>
    )
}

export default AddBanner
