import React, { useState } from 'react'
import { useEffect } from 'react';
import NumberFormat from 'react-number-format'
import { useNavigate, useParams } from 'react-router-dom';
import { uploadBanner } from '../../actions/utilitiesAction';
import constant from '../../constant';
import SuccessMessage from '../common/SuccessMessage';
import ErrorMessage from '../common/ErrorMessage';
import { appCategoryType } from '../../actions/employer';
const AddBanner = (props) => {

    const { id } = useParams()
    const { detail } = props

    const [COMPANY_INDUSTRY_LIST, setCOMPANY_INDUSTRY_LIST] = useState([]);
    const [Banner, setBanner] = useState({
        TITLE: "",
        PAGE_LOCATION: "",
        UPLOAD_BANNER: "",
        LINK: "",
        POSITION: "",
        INDUSTRY: "",
        DESCRIPTION: "",
        SORT_NUMBER: "",
        AD_TYPE: "",
        CTA: "",
        CTA_URL: "",
        COMPANY_NAME: "",
        COMPANY_LOGO: "",
        BANNER_FILE: "",
        AD_TEXT: "",
        AD_VIDEO: "",
        ADDRESS: "",
        COMPANY_PHONE: "",
        COMPANY_EMAIL: "",
        CONTACT_PERSON: "",
        VALIDITY_TYPE: "",
        START_DATE: "",
        END_DATE: "",
        TARGET_CLICK: "",
        TARGET_LEAD: "",
        PRICE: "",
        PRICE_TYPE: ""
    })
    const Navigate = useNavigate()
    const [error, setError] = useState(false)
    const [showPreview, setShowPreview] = useState(false);

    const handlePreviewClick = (event) => {
        event.preventDefault();
        setShowPreview(true);
    };

    const handleReset = (e) => {
        setError(false)
        e.preventDefault();
        id && Navigate(constant.component.addbanner.url)
        setBanner((PrevState) => ({
            ...PrevState,
            TITLE: "",
            PAGE_LOCATION: "",
            UPLOAD_BANNER: "",
            LINK: "",
            POSITION: "",
            INDUSTRY: "",
            DESCRIPTION: "",
            SORT_NUMBER: "",
            AD_TYPE: "",
            CTA: "",
            CTA_URL: "",
            COMPANY_NAME: "",
            COMPANY_LOGO: "",
            BANNER_FILE: "",
            AD_TEXT: "",
            AD_VIDEO: "",
            ADDRESS: "",
            COMPANY_PHONE: "",
            COMPANY_EMAIL: "",
            CONTACT_PERSON: "",
            VALIDITY_TYPE: "",
            START_DATE: "",
            END_DATE: "",
            TARGET_CLICK: "",
            TARGET_LEAD: "",
            PRICE: "",
            PRICE_TYPE: ""
        }));
    }


    const onChange = (e) => {
        const { name, value } = e.target
        setBanner({
            ...Banner,
            [name]: value
        })
    }



    const onBannerUpload = (e) => {
        debugger
        e.preventDefault();
        let name = e.target.name;
        let file = new FormData();
        file.append("file", e.target.files[0]);
        if (e.target.files[0]) {
            uploadBanner(file)
                .then((res) => {
                    setBanner({
                        ...Banner,
                        [name]: res.result.fileName,
                    });
                })
                .catch((err) => {
                    alert(err);
                });
        }
    };
    const onBannerUploadLogo = (e) => {
        debugger
        e.preventDefault();
        let name = e.target.name;
        let file = new FormData();
        file.append("file", e.target.files[0]);
        if (e.target.files[0]) {
            uploadBanner(file)
                .then((res) => {
                    setBanner({
                        ...Banner,
                        [name]: res.result.fileName,
                    });
                })
                .catch((err) => {
                    alert(err);
                });
        }
    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return(emailRegex.test(email));
      };
      const validatePhone = (phone) => {
        const phoneNumberRegex = /^\d{10}$/; // Assumes 10-digit phone number
        return(phoneNumberRegex.test(phone));
      };

      const validateURL = (url) => {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return(urlRegex.test(url));
      };
    
    const onSubmit = (e) => {
        debugger
        e.preventDefault()
        if (Banner.TITLE.trim().length == 0 ||
            Banner.PAGE_LOCATION.trim().length == 0 ||
            // !validateURL(Banner.LINK.trim()) ||
            Banner.POSITION.trim().length == 0 ||
            Banner.DESCRIPTION.trim().length == 0 ||
            Banner.SORT_NUMBER.length == 0 ||
            Banner.ADDRESS.trim().length == 0 ||
            !validatePhone(Banner.COMPANY_PHONE.trim())||
           // Banner.COMPANY_PHONE.trim().length == 0 ||
            !validateEmail(Banner.COMPANY_EMAIL.trim())||
            // Banner.COMPANY_EMAIL.trim().length == 0 ||
            Banner.CONTACT_PERSON.trim().length == 0 ||
            Banner.COMPANY_LOGO.trim().length == 0 ||
            Banner.COMPANY_NAME.trim().length == 0 ||
            Banner.VALIDITY_TYPE.toString().trim().length == 0 ||
            Banner.TARGET_CLICK.length == 0 ||
            Banner.TARGET_LEAD.length == 0 ||
            Banner.PRICE.length == 0 ||
            Banner.PRICE_TYPE.toString().trim().length == 0 ||
            Banner.CTA.trim().length == 0 ||
            !validateURL(Banner.CTA_URL.trim()) ||
        
            Banner.INDUSTRY.trim().length == 0


        ) {
            setError(true);
            return false;
        } else if (
            Banner.VALIDITY_TYPE !== "L" &&
            (Banner.START_DATE.toString().trim().length == 0 ||
                Banner.END_DATE.toString().trim().length == 0)
        ) {
            setError(true);
            return false;
        }

        const model = {
            TITLE: Banner.TITLE,
            PAGE_LOCATION: Banner.PAGE_LOCATION,
            AD_TYPE: Banner.AD_TYPE,
            LINK: Banner.LINK,
            POSITION: Banner.POSITION,
            DESCRIPTION: Banner.DESCRIPTION,
            SORT_NUMBER: Banner.SORT_NUMBER,
            ADDRESS: Banner.ADDRESS,
            COMPANY_PHONE: Banner.COMPANY_PHONE,
            COMPANY_EMAIL: Banner.COMPANY_EMAIL,
            CONTACT_PERSON: Banner.CONTACT_PERSON,
            COMPANY_LOGO: Banner.COMPANY_LOGO,
            COMPANY_NAME: Banner.COMPANY_NAME,
            VALIDITY_TYPE: Banner.VALIDITY_TYPE,
            START_DATE: Banner.START_DATE,
            END_DATE: Banner.END_DATE,
            TARGET_CLICK: Banner.TARGET_CLICK,
            TARGET_LEAD: Banner.TARGET_LEAD,
            PRICE: Banner.PRICE,
            PRICE_TYPE: Banner.PRICE_TYPE,
            CTA: Banner.CTA,
            CTA_URL: Banner.CTA_URL,
            BANNER_FILE: Banner.BANNER_FILE,
            AD_VIDEO: Banner.AD_VIDEO,
            AD_TEXT: Banner.AD_TEXT,
            INDUSTRY: Banner.INDUSTRY
        };
        if (detail) {
            model.BANNER_ID = detail.BANNER_ID
        }
        props.onSubmit(model)

        setBanner((PrevState) => ({
            ...PrevState,
            TITLE: "",
            PAGE_LOCATION: "",
            UPLOAD_BANNER: "",
            LINK: "",
            POSITION: "",
            INDUSTRY: "",
            DESCRIPTION: "",
            SORT_NUMBER: "",
            AD_TYPE: "",
            CTA: "",
            CTA_URL: "",
            COMPANY_NAME: "",
            COMPANY_LOGO: "",
            BANNER_FILE: "",
            AD_TEXT: "",
            AD_VIDEO: "",
            ADDRESS: "",
            COMPANY_PHONE: "",
            COMPANY_EMAIL: "",
            CONTACT_PERSON: "",
            VALIDITY_TYPE: "",
            START_DATE: "",
            END_DATE: "",
            TARGET_CLICK: "",
            TARGET_LEAD: "",
            PRICE: "",
            PRICE_TYPE: ""
        }));
        
        setError(false);

    }
    useEffect(() => {
        (id ? document.title = constant.title.EditBanner : document.title = constant.title.AddBanner)
    }, [])
    useEffect(() => {
        if (detail) {
            setBanner({

                TITLE: detail.TITLE,
                PAGE_LOCATION: detail.PAGE_LOCATION,
                BANNER_FILE: detail.BANNER_FILE,
                LINK: detail.LINK,
                POSITION: detail.POSITION,
                DESCRIPTION: detail.DESCRIPTION,
                SORT_NUMBER: detail.SORT_NUMBER,
                AD_TYPE: detail.AD_TYPE,
                CTA: detail.CTA,
                CTA_URL: detail.CTA_URL,
                COMPANY_LOGO: detail.COMPANY_LOGO,
                COMPANY_NAME: detail.COMPANY_NAME,
                AD_TYPE_IMAGE: detail.AD_TYPE_IMAGE,
                AD_TEXT: detail.AD_TEXT,
                AD_VIDEO: detail.AD_VIDEO,
                ADDRESS: detail.ADDRESS,
                COMPANY_PHONE: detail.COMPANY_PHONE,
                COMPANY_EMAIL: detail.COMPANY_EMAIL,
                CONTACT_PERSON: detail.CONTACT_PERSON,
                VALIDITY_TYPE: detail.VALIDITY_TYPE,
                START_DATE: detail.START_DATE,
                END_DATE: detail.END_DATE,
                TARGET_CLICK: detail.TARGET_CLICK,
                TARGET_LEAD: detail.TARGET_LEAD,
                PRICE: detail.PRICE,
                PRICE_TYPE: detail.PRICE_TYPE,
                INDUSTRY: detail.INDUSTRY
            })
        }
    }, [])
    useEffect(() => {
        appCategoryType()
            .then((res) => {
                if (res.status) {
                    setCOMPANY_INDUSTRY_LIST(res.result.list);
                } else {
                    alert(res.error);
                }
            })
            .catch((err) => {
                alert(err);
            });
    }, []);

    return (

        <React.Fragment>

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 " style={{ fontSize: '18px' }}>Banner Advertisement</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Advertisement</a></li>
                                            <li className="breadcrumb-item active"> Add Banner</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Banner Information</h4>

                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div className='row'>

                                            <div className='col-lg-7'>

                                                <div id="basic-example">
                                                    <section>
                                                        <form>
                                                            <div className="row">



                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">Page Location<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <select
                                                                            value={Banner.PAGE_LOCATION}
                                                                            className='form-control'
                                                                            onChange={onChange}
                                                                            name="PAGE_LOCATION"
                                                                        >
                                                                            <option value="">Select Page  Location </option>
                                                                            <option value="web_job_list">Web Job List</option>
                                                                            <option value="web_job_detail">Web Job Detail</option>
                                                                            <option value="app_user_dashboard">App User Dashboard</option>

                                                                        </select>
                                                                        {error && Banner.PAGE_LOCATION.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-address-input">Position <span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <select
                                                                            value={Banner.POSITION}
                                                                            onChange={onChange}
                                                                            className='form-control'
                                                                            name="POSITION"
                                                                        >
                                                                            <option value="">Select  Position </option>
                                                                            <option value="LEFT">Left </option>
                                                                            <option value="RIGHT">Right </option>
                                                                            <option value="TOP">Top</option>
                                                                            <option value="BOTTOM">Bottom</option>
                                                                            <option value="MIDDLE LEFT">Middle Left</option>
                                                                            <option value="MIDDLE RIGHT">Middle Right</option>
                                                                            <option value="MIDDLE CENTRE">Middle Centre</option>



                                                                        </select>
                                                                        {error && Banner.POSITION.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                                    </div>

                                                                </div>


                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <div className='mb-3'>
                                                                        <label for="basicpill-firstname-input"> Ad Type<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <select
                                                                            value={Banner.AD_TYPE}
                                                                            onChange={onChange}
                                                                            className='form-control'
                                                                            name="AD_TYPE"
                                                                        >
                                                                            <option value=""> Select Type</option>
                                                                            <option value="T"> Text</option>
                                                                            <option value="I">

                                                                                Image
                                                                            </option>
                                                                            <option value="V"> Video</option>
                                                                            <option value="TI"> Text+Image</option>
                                                                            <option value="TV"> Text+Video</option>

                                                                        </select>
                                                                        {error && Banner.AD_TYPE.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                                    </div>
                                                                </div>
                                                                {Banner.AD_TYPE == 'T' ?

                                                                    <div className='col-lg-3'>
                                                                        <div className='mb-3'>
                                                                            <label for="basicpill-firstname-input"> Text<span style={{ color: "#FF0000" }}>*</span></label>
                                                                            <input type='text'
                                                                                className='form-control'
                                                                                placeholder='Enter Text'
                                                                                value={Banner.AD_TEXT}
                                                                                onChange={onChange}
                                                                                NAME="AD_TEXT"
                                                                            >

                                                                            </input>
                                                                        </div>
                                                                    </div>
                                                                    : Banner.AD_TYPE == 'I' ?
                                                                        <div className='col-lg-3' >
                                                                            <div className='mb-3'>
                                                                                <label for="basicpill-firstname-input"> Image<span style={{ color: "#FF0000" }}>*</span></label>
                                                                                <input type='file'
                                                                                    className='form-control'
                                                                                    onChange={(e) => {
                                                                                        e.preventDefault();
                                                                                        onBannerUpload(e);
                                                                                    }}

                                                                                    placeholder="Enter Link"
                                                                                    NAME="BANNER_FILE"
                                                                                >

                                                                                </input>
                                                                            </div>
                                                                        </div>
                                                                        : Banner.AD_TYPE == 'V' ?
                                                                            <div className='col-lg-3'>
                                                                                <div className='mb-3'>
                                                                                    <label for="basicpill-firstname-input"> Video<span style={{ color: "#FF0000" }}>*</span></label>
                                                                                    <input type='file'
                                                                                        className='form-control'
                                                                                        placeholder='Enter URL'
                                                                                        value={Banner.AD_VIDEO}

                                                                                        onChange={onChange}

                                                                                        NAME="AD_VIDEO"
                                                                                    >

                                                                                    </input>
                                                                                </div>
                                                                            </div>
                                                                            : Banner.AD_TYPE == 'TV' ?
                                                                                <>
                                                                                    <div className='col-lg-3'>
                                                                                        <div className='mb-3'>
                                                                                            <label for="basicpill-firstname-input"> Text<span style={{ color: "#FF0000" }}>*</span></label>
                                                                                            <input type='text'
                                                                                                className='form-control'
                                                                                                placeholder='Enter Text'
                                                                                                value={Banner.AD_TEXT}
                                                                                                onChange={onChange}
                                                                                                NAME="AD_TEXT"
                                                                                            >

                                                                                            </input>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='col-lg-3'>
                                                                                        <div className='mb-3'>
                                                                                            <label for="basicpill-firstname-input"> Video<span style={{ color: "#FF0000" }}>*</span></label>
                                                                                            <input type='file'
                                                                                                className='form-control'
                                                                                                placeholder='Enter URL'
                                                                                                value={Banner.AD_VIDEO}

                                                                                                onChange={onChange}

                                                                                                NAME="AD_VIDEO"
                                                                                            >

                                                                                            </input>
                                                                                        </div>
                                                                                    </div>
                                                                                </>

                                                                                : Banner.AD_TYPE == 'TI' ?
                                                                                    <>
                                                                                        <div className='col-lg-3'>
                                                                                            <div className='mb-3'>
                                                                                                <label for="basicpill-firstname-input"> Text<span style={{ color: "#FF0000" }}>*</span></label>
                                                                                                <input type='text'
                                                                                                    className='form-control'
                                                                                                    placeholder='Enter Text'
                                                                                                    value={Banner.AD_TEXT}
                                                                                                    onChange={onChange}
                                                                                                    NAME="AD_TEXT"
                                                                                                >

                                                                                                </input>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='col-lg-3'>
                                                                                            <div className='mb-3'>
                                                                                                <label for="basicpill-firstname-input"> Image<span style={{ color: "#FF0000" }}>*</span></label>
                                                                                                <input type='file'
                                                                                                    className='form-control'



                                                                                                    onChange={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        onBannerUpload(e);
                                                                                                    }}

                                                                                                    NAME="BANNER_FILE"
                                                                                                />


                                                                                            </div>
                                                                                        </div>
                                                                                    </>

                                                                                    : ''}






                                                            </div>
                                                            <div className='row'>



                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-address-input">Link </label>
                                                                        <input
                                                                            type='URL'
                                                                            value={Banner.LINK}
                                                                            className='form-control'
                                                                            onChange={onChange}
                                                                            placeholder="Enter Link"
                                                                            NAME="LINK"
                                                                        // id="basicpill-firstname-input"
                                                                        />
                                                                        {/* {error && Banner.LINK.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                                        {Banner.LINK.trim().length != 0 && !validateURL(Banner.LINK.trim()) ? <span className='text-danger ml-1'> Enter Correct URL</span> : ''} */}

                                                                    </div>



                                                                </div>

                                                            </div>



                                                            <div className="row">

                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">Headline / Title <span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <input
                                                                            value={Banner.TITLE}
                                                                            onChange={onChange}
                                                                            className='form-control'
                                                                            placeholder="Enter Title"
                                                                            // id="basicpill-firstname-input"
                                                                            // value={banner.TITLE}
                                                                            name='TITLE'
                                                                        />
                                                                        {error && Banner.TITLE.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                                    </div>
                                                                </div>


                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">Description<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <input
                                                                            value={Banner.DESCRIPTION}
                                                                            className='form-control'
                                                                            onChange={onChange}
                                                                            placeholder="Enter Description"
                                                                            // id="basicpill-firstname-input"
                                                                            name="DESCRIPTION"
                                                                            type='text'
                                                                        />
                                                                        {error && Banner.DESCRIPTION.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <div className='mb-3'>
                                                                        <label for="basicpill-firstname-input"> Call To Action<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <select value={Banner.CTA}
                                                                            onChange={onChange}
                                                                            className='form-control'
                                                                            name="CTA">
                                                                            <option value=''> Select Type</option>
                                                                            <option value='LM'> Learn More</option>
                                                                            <option value='SN'>

                                                                                Shop now
                                                                            </option>
                                                                            <option value='SU'> Sign Up </option>
                                                                            <option value='D'> Downloads</option>
                                                                            <option value='RN'> Register Now</option>
                                                                            <option value='CN'> Call Now</option>
                                                                            <option value='CMO'> Claim My Offer</option>
                                                                            <option value='VW'> Visit Website </option>

                                                                        </select>
                                                                        {error && Banner.CTA.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <div className='mb-3'>
                                                                        <label for="basicpill-firstname-input">CTA URL<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <input type='text'
                                                                            className='form-control'
                                                                            placeholder='Enter CTA URL'
                                                                            value={Banner.CTA_URL}
                                                                            onChange={onChange}
                                                                            NAME="CTA_URL"
                                                                        />
                                                                        {error && Banner.CTA_URL.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                                        {Banner.CTA_URL.trim().length != 0 && !validateURL(Banner.CTA_URL.trim()) ? <span className='text-danger ml-1'> Enter Correct URL</span> : ''}


                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <div className='mb-3'>
                                                                        <label for="basicpill-firstname-input"> Price<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <select
                                                                            className={
                                                                                error &&
                                                                                    Banner.PRICE_TYPE.toString().trim()
                                                                                        .length <= 0
                                                                                    ? "form-control is-invalid "
                                                                                    : "form-control"
                                                                            }
                                                                            name="PRICE_TYPE"
                                                                            value={Banner.PRICE_TYPE}
                                                                            onChange={onChange}
                                                                        >
                                                                            <option value=''> Select Type</option>
                                                                            <option value='FIXED'>Fixed</option>
                                                                            <option value='CPC'> CPC </option>
                                                                            <option value='CPL'> CPL  </option>
                                                                        </select>
                                                                        {error &&
                                                                            Banner.PRICE_TYPE.toString().trim()
                                                                                .length <= 0 ? (
                                                                            <span className="text-danger ml-1">
                                                                                {" "}
                                                                                This Field is Required
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <div className='mb-3'>
                                                                        <label for="basicpill-firstname-input"> Enter Amount<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <input type='number'
                                                                            className='form-control'
                                                                            placeholder='Enter Amount'
                                                                            value={Banner.PRICE}
                                                                            onChange={onChange}
                                                                            NAME="PRICE"
                                                                        />

                                                                        {error && Banner.PRICE.length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <div className='mb-3'>
                                                                        <label for="basicpill-firstname-input"> Target Lead<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <input type='number'
                                                                            className='form-control'
                                                                            placeholder='Enter Lead'
                                                                            value={Banner.TARGET_LEAD}
                                                                            onChange={onChange}
                                                                            NAME="TARGET_LEAD"
                                                                        />
                                                                        {error && Banner.TARGET_LEAD.length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <div className='mb-3'>
                                                                        <label for="basicpill-firstname-input"> Target Click (Count)<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <input type='number'
                                                                            className='form-control'
                                                                            placeholder='Enter Click'
                                                                            value={Banner.TARGET_CLICK}
                                                                            onChange={onChange}
                                                                            NAME="TARGET_CLICK"
                                                                        />
                                                                        {error && Banner.TARGET_CLICK.length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}


                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input"> Validity Type<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <select className={
                                                                            error &&
                                                                                Banner.VALIDITY_TYPE.toString().trim()
                                                                                    .length <= 0
                                                                                ? "form-control is-invalid "
                                                                                : "form-control"
                                                                        }
                                                                            value={Banner.VALIDITY_TYPE}
                                                                            onChange={onChange}
                                                                            name="VALIDITY_TYPE"
                                                                        >
                                                                            <option value=''> Select Type</option>
                                                                            <option value='L'> Lifetime</option>
                                                                            <option value='SP'>
                                                                                Specific Period
                                                                            </option>
                                                                        </select>
                                                                        {error &&
                                                                            Banner.VALIDITY_TYPE.toString().trim()
                                                                                .length <= 0 ? (
                                                                            <span className="text-danger ml-1">
                                                                                {" "}
                                                                                This Field is Required
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {Banner.VALIDITY_TYPE !== "L" && (
                                                                    <>
                                                                        <div className="col-lg-3">
                                                                            <div className="mb-3">
                                                                                <label for="basicpill-firstname-input">
                                                                                    Start Date{" "}
                                                                                    <span style={{ color: "#FF0000" }}>
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                                <input
                                                                                    type="date"
                                                                                    className={
                                                                                        error &&
                                                                                            Banner.START_DATE.toString().trim()
                                                                                                .length <= 0
                                                                                            ? "form-control is-invalid "
                                                                                            : "form-control"
                                                                                    }
                                                                                    name="START_DATE"
                                                                                    value={Banner.START_DATE}
                                                                                    onChange={onChange}

                                                                                />
                                                                                {error &&
                                                                                    Banner.START_DATE.toString().trim()
                                                                                        .length <= 0 ? (
                                                                                    <span className="text-danger ml-1">
                                                                                        {" "}
                                                                                        This Field is Required
                                                                                    </span>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3">
                                                                            <div className="mb-3">
                                                                                <label for="basicpill-firstname-input">
                                                                                    End Date{" "}
                                                                                    <span style={{ color: "#FF0000" }}>
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                                <input
                                                                                    type="date"
                                                                                    className={
                                                                                        error &&
                                                                                            Banner.END_DATE.toString().trim()
                                                                                                .length <= 0
                                                                                            ? "form-control is-invalid "
                                                                                            : "form-control"
                                                                                    }
                                                                                    name="END_DATE"
                                                                                    value={Banner.END_DATE}
                                                                                    onChange={onChange}


                                                                                />
                                                                                {error &&
                                                                                    Banner.END_DATE.toString().trim()
                                                                                        .length <= 0 ? (
                                                                                    <span className="text-danger ml-1">
                                                                                        {" "}
                                                                                        This Field is Required
                                                                                    </span>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">Sort Number<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <NumberFormat
                                                                            value={Banner.SORT_NUMBER}
                                                                            allowLeadingZeros={false}
                                                                            allowNegative={false}
                                                                            thousandSeparator={false}
                                                                            className='form-control'
                                                                            placeholder="Enter Sort Number"
                                                                            name="SORT_NUMBER"
                                                                            onChange={onChange}
                                                                        //id="basicpill-firstname-input"
                                                                        />
                                                                        {error && Banner.SORT_NUMBER.length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                                    </div>
                                                                </div>





                                                            </div>



                                                            <h4 className="card-title mb-4 mt-4">Company Information</h4>


                                                            <div className='row'>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">Company Name<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <input value={Banner.COMPANY_NAME}
                                                                            className='form-control'
                                                                            onChange={onChange}

                                                                            NAME="COMPANY_NAME" type='text' placeholder='Enter Company Name'>

                                                                        </input>
                                                                        {error && Banner.COMPANY_NAME.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">Company Logo<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <input
                                                                            className='form-control'
                                                                            onChange={(e) => {
                                                                                e.preventDefault();
                                                                                onBannerUploadLogo(e);
                                                                            }}

                                                                            NAME="COMPANY_LOGO" type='file' placeholder='Enter Company Logo'>

                                                                        </input>
                                                                        {error && Banner.COMPANY_LOGO.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                                    </div>
                                                                </div>


                                                            </div>


                                                            <div className='row'>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">Contact Person<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <input type='text' className='form-control' placeholder='Enter Contact Person' value={Banner.CONTACT_PERSON} onChange={onChange} NAME="CONTACT_PERSON" />
                                                                        {error && Banner.CONTACT_PERSON.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}


                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">Email<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <input type='email' id="email" className='form-control' placeholder='Enter Email' value={Banner.COMPANY_EMAIL} onChange={onChange} NAME="COMPANY_EMAIL" />
                                                                        {error && Banner.COMPANY_EMAIL.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                                        {Banner.COMPANY_EMAIL.trim().length != 0 && !validateEmail(Banner.COMPANY_EMAIL.trim()) ? <span className='text-danger ml-1'> Enter Correct Email</span> : ''}

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">Phone<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <NumberFormat type='text' maxLength="10" className='form-control' placeholder='Enter Phone' value={Banner.COMPANY_PHONE} onChange={onChange} NAME="COMPANY_PHONE" />
                                                                        {error && Banner.COMPANY_PHONE.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                                        {Banner.COMPANY_PHONE.trim().length != 0 && !validatePhone(Banner.COMPANY_PHONE.trim()) ? <span className='text-danger ml-1'> Please enter a valid 10-digit phone number.</span> : ''}


                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">Industry<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <select
                                                                            className={
                                                                                error &&
                                                                                    Banner.INDUSTRY.toString().trim()
                                                                                        .length <= 0
                                                                                    ? "form-select is-invalid"
                                                                                    : "form-select"
                                                                            }
                                                                            value={Banner.INDUSTRY}
                                                                            name="INDUSTRY"
                                                                            onChange={onChange}
                                                                        >
                                                                            <option>Select Category</option>
                                                                            {COMPANY_INDUSTRY_LIST.length > 0 &&
                                                                                COMPANY_INDUSTRY_LIST.map((item, index) => {
                                                                                    return (
                                                                                        <option
                                                                                            key={index}
                                                                                            value={item.INDUSTRY}
                                                                                        >
                                                                                            {item.APP_CATEGORY_NAME}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                        </select>
                                                                        {error &&
                                                                            Banner.INDUSTRY.toString().trim()
                                                                                .length <= 0 ? (
                                                                            <span className="text-danger ml-1">
                                                                                {" "}
                                                                                This Field is Required
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">Address</label>
                                                                        <input type='text' className='form-control' placeholder='Enter Address' value={Banner.ADDRESS} onChange={onChange} NAME="ADDRESS" />
                                                                        {error && Banner.ADDRESS.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}


                                                                    </div>
                                                                </div>
                                                            </div>









                                                            <div className="row">

                                                                <div className='col-lg-6'>
                                                                    <div className='mb-3'>
                                                                        <button type="button" className="btn btn-info " onClick={handlePreviewClick}> Preview</button>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <button onClick={onSubmit}
                                                                            type="button" className="btn btn-info waves-effect btn-label waves-light"> <i className="bx bx-check-double label-icon"></i>{detail ? "Save Changes" : "Create Banner"}</button>
                                                                        &nbsp;&nbsp;
                                                                        <button onClick={(e) => handleReset(e)} type="button" className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </form>

                                                    </section>
                                                </div>
                                            </div>

                                            <div className='col-lg-5'>
                                                <div className='mb-3 mt-4'>
                                                    <div className='resultprev'>
                                                        <div className='head_prev'>
                                                            <div className='prev-icons'>
                                                                <div className='your_logo'>
                                                                    {showPreview ? (
                                                                        <img
                                                                        src={`${"https://s3-jobzseed.s3.ap-south-1.amazonaws.com/advertisement/banner/images/"}${Banner.COMPANY_LOGO}`} 
                                                                        alt="Preview" style={{ maxWidth: '300px', maxHeight: '300px' }} />
                                                                    ) : <img width="300" height="300" src={
                                                                        "https://ik.imagekit.io/demo/medium_cafe_B1iTdD0C.jpg   "
                                                                    } class=" lazyloaded"></img>}

                                                                </div>
                                                                <div className='txt_prevous'>
                                                                    <h4>{showPreview ? Banner.COMPANY_NAME : "Default Company Name"}</h4>
                                                                    <p>Sponsored</p>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className='Prev_body'>
                                                            <div className='result_row_msg_area'>


                                                                <a href={Banner.LINK} target='blank'> <div className='image_result_fb'>
                                                                    {showPreview ? (Banner.AD_TYPE == "I" ?
                                                                        <img src={`${"https://s3-jobzseed.s3.ap-south-1.amazonaws.com/advertisement/banner/images/"}${Banner.BANNER_FILE}`} alt="Preview" style={{ maxWidth: '300px', maxHeight: 'auto' }} /> : Banner.AD_TYPE == 'T' ? Banner.AD_TEXT : ""
                                                                    ) : <img width="300" height="300" src={
                                                                        "https://ik.imagekit.io/demo/medium_cafe_B1iTdD0C.jpg   "
                                                                    } class=" lazyloaded"></img>}



                                                                </div></a>
                                                                <div className='under_spon'>
                                                                    <div className='spon_row'>
                                                                        <h5>{showPreview ? Banner.TITLE : "Your Title Here"}</h5>
                                                                        <p>{showPreview ? Banner.DESCRIPTION : "Your Description Here"}</p>
                                                                    </div>
                                                                    <a href={Banner.CTA_URL} target='blank'>
                                                                        <div className='spon_footer'>
                                                                            <p></p>
                                                                            <button>
                                                                                {showPreview ? (Banner.CTA == 'LM' ? 'Learn more' : Banner.CTA == 'SN' ? 'Shop Now' : Banner.CTA == "SU" ? 'Sign Up' : Banner.CTA == "D" ? 'Downloads' : Banner.CTA == "RN" ? 'Register Now' : Banner.CTA == "CN" ? "Call Now" : Banner.CTA == "CMO" ? "Claim My Offer" : Banner.CTA == "VW" ? "View Website" : "") : "Call to action"}
                                                                            </button>
                                                                        </div></a>
                                                                </div>
                                                            </div>
                                                        </div>





                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddBanner
